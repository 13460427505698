



























import { Component, Vue, Prop } from 'nuxt-property-decorator';

import { IconArrowRight } from '@/icons';

@Component({
  components: {
    IconArrowRight,
  },
})
export default class BlockDigitalScene extends Vue {
  @Prop(String)
  title!: string

  @Prop(String)
  body!: string

  @Prop(String)
  buttonUrl!: string

  @Prop(String)
  buttonText!: string

  @Prop(Object)
  image!: object
}
